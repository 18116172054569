import ApiService from "@/core/services/api.service";

// action types
export const GET_STATUSES = "getStatuses";
export const UPDATE_STATUS_DETAILS = "updateStatusDetails";
export const CREATE_STATUS_DETAILS = "createStatusDetails";
export const DELETE_STATUS_DETAILS = "deleteStatusDetails";

// mutation types
export const SET_STATUSES_ERROR = "setSTATUSesError";
export const SET_STATUSES_INFO = "setSTATUSsInfo";

const state = {
  errors: null,
  statuses: {},
};

const getters = {
  currentStatusesData(state) {
    console.log("currentStatusesData");
    return state.statuses;
  },
};

const actions = {
  [GET_STATUSES](context) {
    console.log("GET_STATUSES");
    ApiService.setHeader();
    ApiService.get("api/managestatuses")
      .then(({ data }) => {
        console.log(data.data.response.statuses);
        context.commit(SET_STATUSES_INFO, data.data.response.userstatuses);
      })
      .catch(({ response }) => {
        context.commit(SET_STATUSES_ERROR, response.data.errors);
      });
  },
  [UPDATE_STATUS_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_STATUS_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/managestatuses/update", data).catch(({ response }) => {
      context.commit(SET_STATUSES_ERROR, response.data.errors);
    });
  },
  [CREATE_STATUS_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_STATUS_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/managestatuses/create", data).catch(({ response }) => {
      context.commit(SET_STATUSES_ERROR, response.data.errors);
    });
  },
  [DELETE_STATUS_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_STATUS_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/managestatuses/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_STATUSES_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_STATUSES_ERROR](state, error) {
    state.errors = error;
  },
  [SET_STATUSES_INFO](state, statuses) {
    state.statuses = statuses;
    console.log(state.statuses);
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
