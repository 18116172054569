import ApiService from "@/core/services/api.service";

// action types
export const GET_FRAMEWORKEXCEPTIONS = "getFRAMEWORKEXCEPTIONs";
export const UPDATE_FRAMEWORKEXCEPTION_DETAILS = "updateFRAMEWORKEXCEPTIONDetails";
export const CREATE_FRAMEWORKEXCEPTION_DETAILS = "createFRAMEWORKEXCEPTIONDetails";
export const DELETE_FRAMEWORKEXCEPTION_DETAILS = "deleteFRAMEWORKEXCEPTIONDetails";

// mutation types
export const SET_FRAMEWORKEXCEPTIONS_ERROR = "setFRAMEWORKEXCEPTIONsError";
export const SET_FRAMEWORKEXCEPTIONS_INFO = "setFRAMEWORKEXCEPTIONsInfo";

const state = {
  errors: null,
  frameworkexceptions: {},
};

const getters = {
  currentFRAMEWORKEXCEPTIONsData(state) {
    console.log("curentframeworkexceptions");
    return state.frameworkexceptions;
  },
};

const actions = {
  [GET_FRAMEWORKEXCEPTIONS](context,payload) {
    const data = payload;
    console.log("GET_FRAMEWORKEXCEPTIONS",payload);
    ApiService.setHeader();
    ApiService.post("api/manageframeworkexceptions",data)
      .then(({ data }) => {
        console.log('data.data.response.frameworkexceptions');
        console.log(data.data.response);
        context.commit(
          SET_FRAMEWORKEXCEPTIONS_INFO,
          data.data.response.frameworkexceptions
        );
      })
      .catch(({ response }) => {
        context.commit(SET_FRAMEWORKEXCEPTIONS_ERROR, response.data.errors);
      });
  },
  [UPDATE_FRAMEWORKEXCEPTION_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_FRAMEWORKEXCEPTION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworkexceptions/update", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKEXCEPTIONS_ERROR, response.data.errors);
      }
    );
  },
  [CREATE_FRAMEWORKEXCEPTION_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_FRAMEWORKEXCEPTION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworkexceptions/create", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKEXCEPTIONS_ERROR, response.data.errors);
      }
    );
  },
  [DELETE_FRAMEWORKEXCEPTION_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_FRAMEWORKEXCEPTION_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/manageframeworkexceptions/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKEXCEPTIONS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_FRAMEWORKEXCEPTIONS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FRAMEWORKEXCEPTIONS_INFO](state, frameworkexceptions) {
    state.frameworkexceptions = frameworkexceptions;
    console.log(state.frameworkexceptions);
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
