import ApiService from "@/core/services/api.service";

// action types
export const GET_USERS = "getUsers";
export const UPDATE_USER_DETAILS = "updateUserDetails";
export const CREATE_USER_DETAILS = "createUserDetails";
export const DELETE_USER_DETAILS = "deleteUserDetails";
export const UPDATE_REVIEW_DETAILS = "updateRiskReviewDetails";


// mutation types
export const SET_USERS_ERROR = "setUsersError";
export const SET_USERS_INFO = "setUsersInfo";

const state = {
  errors: null,
  users: {},
  departments: {},
  organisations: {},
  user_roles: {},
};

const getters = {
  currentUsers(state) {
    return state.users;
  },
  currentUsersData(state) {
    return state;
  },
};

const actions = {
  [GET_USERS](context) {
    console.log("GET_USERS");
    ApiService.setHeader();
    ApiService.get("api/manageusers")
      .then(({ data }) => {
        console.log(data);
        context.commit(SET_USERS_INFO, data.data.response);
      })
      .catch(({ response }) => {
        context.commit(SET_USERS_ERROR, response.data.errors);
      });
  },
  [UPDATE_USER_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_USER_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageusers/update", data).catch(({ response }) => {
      context.commit(SET_USERS_ERROR, response.data.errors);
    });
  },
  [UPDATE_REVIEW_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_REVIEW_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/riskreview/update", data).catch(({ response }) => {
      context.commit(SET_USERS_ERROR, response.data.errors);
    });
  },  
  [CREATE_USER_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_USER_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageusers/create", data).catch(({ response }) => {
      context.commit(SET_USERS_ERROR, response.data.errors);
    });
  },
  [DELETE_USER_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_USER_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/manageusers/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_USERS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_USERS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USERS_INFO](state, response) {
    state.users = response.users;
    state.departments = response.departments;
    state.organisations = response.organisations;
    state.user_roles = response.user_roles;
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
