import ApiService from "@/core/services/api.service";

// action types
export const GET_FRAMEWORK = "getFramework";
export const GET_DASHBOARD_STATS = "getDashboardStats";
export const GET_DASHBOARD_STATS_PERC = "getDashboardStatsPerc";
export const UPDATE_FRAMEWORK = "updateFramework";
export const UPDATE_FRAMEWORK_NOTES = "updateFrameworkNotes";


// mutation types
export const SET_FRAMEWORK_ERROR = "setTaskError";
export const SET_FRAMEWORK_INFO = "setTaskInfo";
export const SET_FRAMEWORK_STATS = "setFrameWorkStats";
export const SET_FRAMEWORK_STATS_PERC = "setFrameWorkStatsPerc";


const state = {
  errors: null,
  framework: {},  
  stats: {},
  stats_perc: {}
};

const getters = {
  currentFramework(state) {
    return state.framework;
  },
  currentFrameworkStats(state) {
    return state.stats;
  },
  currentFrameworkStatsPerc(state) {
    return state.stats_perc;
  },  
};

const actions = {
  [GET_FRAMEWORK](context,frameworkid) {
    console.log("GET_FRAMEWORK",frameworkid);

    if (frameworkid == undefined) {
      frameworkid = '';
    }

    ApiService.setHeader();
    ApiService.get("api/framework/" + frameworkid)
      .then(({ data }) => {
        context.commit(SET_FRAMEWORK_INFO, data.data.response);
      })
      .catch(({ response }) => {
        context.commit(SET_FRAMEWORK_ERROR, response.data.errors);
      });
  },
  [GET_DASHBOARD_STATS](context,frameworkid) {
    console.log("GET_DASHBOARD_STATS",frameworkid);

    if (frameworkid == undefined) {
      frameworkid = '';
    }

    ApiService.setHeader();
    ApiService.get("api/frameworkstats/" + frameworkid)
      .then(({ data }) => {
        console.log("GET_DASHBOARD_STATS",data);
        context.commit(SET_FRAMEWORK_STATS, data.data.response);
      })
      .catch(({ response }) => {
        console.log("GET_DASHBOARD_STATS ERROR",response);
        context.commit(SET_FRAMEWORK_ERROR, response.data.errors);
      });      
  },
  [GET_DASHBOARD_STATS_PERC](context,frameworkid) {
    console.log("GET_DASHBOARD_STATS_PERC",frameworkid);

    if (frameworkid == undefined) {
      frameworkid = '';
    }

    ApiService.setHeader();
    ApiService.get("api/frameworkstatsperc/" + frameworkid)
      .then(({ data }) => {
        console.log("GET_DASHBOARD_STATS_PERC",data);
        context.commit(SET_FRAMEWORK_STATS_PERC, data.data.response);
      })
      .catch(({ response }) => {
        console.log("GET_DASHBOARD_STATS_PERC ERROR",response);
        context.commit(SET_FRAMEWORK_ERROR, response.data.errors);
      });      
  },  
  [UPDATE_FRAMEWORK](context, payload) {
    const data = payload;

    console.log("UPDATE_FRAMEWORK");
    ApiService.setHeader();
    ApiService.post("api/framework/update", data).catch(({ response }) => {
      context.commit(SET_FRAMEWORK_ERROR, response.data.errors);
    });
  },
  [UPDATE_FRAMEWORK_NOTES](context, payload) {
    const data = payload;

    console.log("UPDATE_FRAMEWORK_NOTES");
    ApiService.setHeader();
    ApiService.post("api/framework/update/notes", data).catch(({ response }) => {
      context.commit(SET_FRAMEWORK_ERROR, response.data.errors);
    });
  },
};

const mutations = {
  [SET_FRAMEWORK_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FRAMEWORK_INFO](state, framework) {
    state.framework = framework;
    state.errors = {};
  },
  [SET_FRAMEWORK_STATS](state, stats) {
    state.stats = stats;
    state.errors = {};
  },
  [SET_FRAMEWORK_STATS_PERC](state, stats_perc) {
    state.stats_perc = stats_perc;
    state.errors = {};
  },  
};

export default {
  state,
  actions,
  mutations,
  getters,
};
