import ApiService from "@/core/services/api.service";

// action types
export const GET_SYSTEMS = "getSystems";
export const UPDATE_SYSTEM_DETAILS = "updateSystemDetails";
export const CREATE_SYSTEM_DETAILS = "createSystemDetails";
export const DELETE_SYSTEM_DETAILS = "deleteSystemDetails";

// mutation types
export const SET_SYSTEMS_ERROR = "setSYSTEMsError";
export const SET_SYSTEMS_INFO = "setSYSTEMsInfo";

const state = {
  errors: null,
  systems: {},
};

const getters = {
  currentSystemsData(state) {
    return state.systems;
  },
};

const actions = {
  [GET_SYSTEMS](context) {
    console.log("GET_SYSTEMS");
    ApiService.setHeader();
    ApiService.get("api/managesystems")
      .then(({ data }) => {
        console.log(data.data.response.systems);
        context.commit(SET_SYSTEMS_INFO, data.data.response.systems);
      })
      .catch(({ response }) => {
        context.commit(SET_SYSTEMS_ERROR, response.data.errors);
      });
  },
  [UPDATE_SYSTEM_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_SYSTEM_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/managesystems/update", data).catch(({ response }) => {
      context.commit(SET_SYSTEMS_ERROR, response.data.errors);
    });
  },
  [CREATE_SYSTEM_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_SYSTEM_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/managesystems/create", data).catch(({ response }) => {
      context.commit(SET_SYSTEMS_ERROR, response.data.errors);
    });
  },
  [DELETE_SYSTEM_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_SYSTEM_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/managesystems/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_SYSTEMS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_SYSTEMS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_SYSTEMS_INFO](state, systems) {
    state.systems = systems;
    console.log(state.systems);
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
