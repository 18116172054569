import ApiService from "@/core/services/api.service";

// action types
export const GET_FRAMEWORKEXEMPTIONS = "getFRAMEWORKEXEMPTIONs";
export const UPDATE_FRAMEWORKEXEMPTION_DETAILS = "updateFRAMEWORKEXEMPTIONDetails";
export const CREATE_FRAMEWORKEXEMPTION_DETAILS = "createFRAMEWORKEXEMPTIONDetails";
export const DELETE_FRAMEWORKEXEMPTION_DETAILS = "deleteFRAMEWORKEXEMPTIONDetails";

// mutation types
export const SET_FRAMEWORKEXEMPTIONS_ERROR = "setFRAMEWORKEXEMPTIONsError";
export const SET_FRAMEWORKEXEMPTIONS_INFO = "setFRAMEWORKEXEMPTIONsInfo";

const state = {
  errors: null,
  frameworkexemptions: {},
};

const getters = {
  currentFRAMEWORKEXEMPTIONsData(state) {
    console.log("curentframeworkexemptions");
    return state.frameworkexemptions;
  },
};

const actions = {
  [GET_FRAMEWORKEXEMPTIONS](context,payload) {
    const data = payload;
    console.log("GET_FRAMEWORKEXEMPTIONS",payload);
    ApiService.setHeader();
    ApiService.post("api/manageframeworkexemptions",data)
      .then(({ data }) => {
        console.log('data.data.response.frameworkexemptions');
        console.log(data.data.response);
        context.commit(
          SET_FRAMEWORKEXEMPTIONS_INFO,
          data.data.response.frameworkexemptions
        );
      })
      .catch(({ response }) => {
        context.commit(SET_FRAMEWORKEXEMPTIONS_ERROR, response.data.errors);
      });
  },
  [UPDATE_FRAMEWORKEXEMPTION_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_FRAMEWORKEXEMPTION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworkexemptions/update", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKEXEMPTIONS_ERROR, response.data.errors);
      }
    );
  },
  [CREATE_FRAMEWORKEXEMPTION_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_FRAMEWORKEXEMPTION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworkexemptions/create", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKEXEMPTIONS_ERROR, response.data.errors);
      }
    );
  },
  [DELETE_FRAMEWORKEXEMPTION_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_FRAMEWORKEXEMPTION_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/manageframeworkexemptions/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKEXEMPTIONS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_FRAMEWORKEXEMPTIONS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FRAMEWORKEXEMPTIONS_INFO](state, frameworkexemptions) {
    state.frameworkexemptions = frameworkexemptions;
    console.log(state.frameworkexemptions);
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
