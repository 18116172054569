import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const FORGOTTEN = "forgotten";
export const UPDATE_CURRENT = "updateCurrent";
export const UPDATE_PASSWORD = "updateUser";
export const BLANK_AVATAR = "blankAvatar";
export const UPDATE_AVATAR = "updateAvatar";
export const UPDATE_USER_INFO = "uodateUserInfo";


// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_SETTINGS = "setSettings";
export const SET_SYSTEM = "setSystem";
export const SET_FRAMEWORK = "serFramework";

const state = {
  errors: null,
  user: {},
  settings: {
    current_organisation: null,
    current_system: null,
    current_framework: null,
  },
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentSettings(state) {
    return state.settings;
  },
  currentUserAll(state) {
    return state;
  },
  currentOrganisations(state) {
    var retArr = [];
    if (state.user.user_roles) {
      state.user.user_roles.forEach((user_role) => {
        user_role.organisations.forEach((organisation) => {
          retArr.push(organisation);
        });
      });
    }
    return retArr;
  },
  selectedOrganisation(state) {
    var retStr = state.settings.current_organisation;
    if (retStr == null) {
      if (state.user.user_roles) {
        state.user.user_roles.forEach((user_role) => {
          if (retStr == null) {
            retStr = user_role.organisations[0].id;
            state.settings.current_organisation = retStr;
          }
        });
      }
    }
    return retStr;
  },
  currentSystems(state) {
    const retArr = [];
    if (state.user.user_roles) {
      state.user.user_roles.forEach((user_role) => {
        user_role.systems.forEach((system) => {
          retArr.push(system);
        });
      });
    }
    return retArr;
  },
  selectedSystem(state) {
    var retStr = state.settings.current_system;
    if (retStr == null) {
      if (state.user.user_roles) {
        state.user.user_roles.forEach((user_role) => {
          if (retStr == null) {
            retStr = user_role.systems[0].id;
            state.settings.current_system = retStr;
          }
        });
      }
    }
    return retStr;
  },

  currentFrameworks(state) {
    if (state.user.user_roles) {
      return state.user.frameworks;
    } else {
      return [];
    }
  },
  selectedFramework(state) {
    var retStr = state.settings.current_framework;
    if (retStr == null) {
      if (state.user.frameworks) {
        state.user.frameworks.forEach((framework) => {
          if (retStr == null) {
            retStr = framework.primary_framework_id;
            state.settings.current_framework = retStr;
          }
        });
      }
    }
    return retStr;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/auth/login", credentials)
        .then(({ data }) => {
          console.log("Here what post returns", data);
          context.commit(SET_AUTH, data);
          context.commit(
            SET_SETTINGS,
            JSON.parse(data.data.user.settings.frontend)
          );
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data.error);
          reject(response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/auth/register", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  },
  [FORGOTTEN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/auth/resetpassword", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  },  
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/user/verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          context.commit(
            SET_SETTINGS,
            JSON.parse(data.data.user.settings.frontend)
          );
          console.log(data);
        })
        .catch(({ response }) => {
          console.log('dsadadasdsd---->',response)
          context.commit(PURGE_AUTH);
          // context.commit(SET_ERROR, .error);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/user", payload)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
          reject(response.data.errors);
        });
    });
  },
  [UPDATE_AVATAR](context, payload) {
    let data = new FormData();

    data.append("file", payload.file);

    return new Promise((resolve, reject) => {
      ApiService.post("api/user/profileimage", data)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
          reject(response.data.errors);
        });
    });

    //context.commit(SET_PERSONAL_INFO, payload);
  },
  [BLANK_AVATAR](context, payload) {
    console.log(payload);
    return new Promise((resolve, reject) => {
      ApiService.delete("api/user/profileimage")
        .then(({ data }) => {
          console.log(data);
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
          reject(response.data.errors);
        });
    });

    //context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_PASSWORD](context, payload) {
    const data = payload;

    return new Promise((resolve, reject) => {
      ApiService.put("api/user/resetpassword", data)
        .then(({ data }) => {
          context.commit(SET_PASSWORD, data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response.data);
          context.commit(SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  },
  [UPDATE_CURRENT](context, payload) {
    var settingToSave = this.state.auth.settings;

    console.log("settingToSave", settingToSave);

    if (payload.type == "organisation") {
      settingToSave.current_organisation = payload.value;
    }
    if (payload.type == "system") {
      settingToSave.current_system = payload.value;
    }
    if (payload.type == "framework") {
      settingToSave.current_framework = payload.value;
    }

    console.log("settingToSave", settingToSave);

    var settings = {
      current_framework: settingToSave.current_framework,
      current_organisation: settingToSave.current_organisation,
      current_system: settingToSave.current_system,
      
    }

    ApiService.put("api/user/savesettings", { settings: settings }).then(
      ({ data }) => {
        console.log(data);
        context.commit(
          SET_SETTINGS,
          JSON.parse(data.data.user.settings.frontend)
        );

        location.reload();
      }
    );
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    console.log(user);
    state.isAuthenticated = true;
    state.user = user.data.user;
    state.errors = {};
    if (user.data.access_token) {
      JwtService.saveToken(user.data.access_token);
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [SET_SETTINGS](state, settings) {
    state.settings = settings;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
