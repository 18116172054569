import ApiService from "@/core/services/api.service";

// action types
export const GET_FRAMEWORKTASKS = "getFRAMEWORKTASKs";
export const UPDATE_FRAMEWORKTASK_DETAILS = "updateFRAMEWORKTASKDetails";
export const CREATE_FRAMEWORKTASK_DETAILS = "createFRAMEWORKTASKDetails";
export const DELETE_FRAMEWORKTASK_DETAILS = "deleteFRAMEWORKTASKDetails";

// mutation types
export const SET_FRAMEWORKTASKS_ERROR = "setFRAMEWORKTASKsError";
export const SET_FRAMEWORKTASKS_INFO = "setFRAMEWORKTASKsInfo";

const state = {
  errors: null,
  frameworktasks: {},
};

const getters = {
  currentFRAMEWORKTASKsData(state) {
    console.log("curentframeworktasks");
    return state.frameworktasks;
  },
};

const actions = {
  [GET_FRAMEWORKTASKS](context,payload) {
    const data = payload;
    console.log("GET_FRAMEWORKTASKS",payload);
    ApiService.setHeader();
    ApiService.post("api/manageframeworktasks",data)
      .then(({ data }) => {
        console.log('data.data.response.frameworktasks');
        console.log(data.data.response);
        context.commit(
          SET_FRAMEWORKTASKS_INFO,
          data.data.response.frameworktasks
        );
      })
      .catch(({ response }) => {
        context.commit(SET_FRAMEWORKTASKS_ERROR, response.data.errors);
      });
  },
  [UPDATE_FRAMEWORKTASK_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_FRAMEWORKTASK_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworktasks/update", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKTASKS_ERROR, response.data.errors);
      }
    );
  },
  [CREATE_FRAMEWORKTASK_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_FRAMEWORKTASK_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworktasks/create", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKTASKS_ERROR, response.data.errors);
      }
    );
  },
  [DELETE_FRAMEWORKTASK_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_FRAMEWORKTASK_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/manageframeworktasks/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKTASKS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_FRAMEWORKTASKS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FRAMEWORKTASKS_INFO](state, frameworktasks) {
    state.frameworktasks = frameworktasks;
    console.log(state.frameworktasks);
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
