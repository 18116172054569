import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import framework from "./framework.module";
import users from "./users.module";
import userroles from "./userroles.module";
import userdepartments from "./userdepartments.module";
import systems from "./systems.module";
import statuses from "./statuses.module";
import organisations from "./organisations.module";
import frameworkactions from "./frameworkactions.module";
import frameworktasks from "./frameworktasks.module";
import frameworkexceptions from "./frameworkexceptions.module";
import frameworkexemptions from "./frameworkexemptions.module";
import threats from "./threats.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    framework,
    users,
    userroles,
    userdepartments,
    systems,
    statuses,
    organisations,
    frameworkactions,
    frameworktasks,
    frameworkexceptions,
    frameworkexemptions,
    threats
  },
});
