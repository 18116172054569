import ApiService from "@/core/services/api.service";

// action types
export const GET_ORGANISATIONS = "getOrganisations";
export const UPDATE_ORGANISATION_DETAILS = "updateOrganisationDetails";
export const CREATE_ORGANISATION_DETAILS = "createOrganisationDetails";
export const DELETE_ORGANISATION_DETAILS = "deleteOrganisationDetails";

// mutation types
export const SET_ORGANISATIONS_ERROR = "setORGANISATIONsError";
export const SET_ORGANISATIONS_INFO = "setORGANISATIONsInfo";

const state = {
  errors: null,
  organisations: {},
};

const getters = {
  currentOrganisationsData(state) {
    console.log("curentorganisations");
    return state.organisations;
  },
};

const actions = {
  [GET_ORGANISATIONS](context) {
    console.log("GET_ORGANISATIONS");
    ApiService.setHeader();
    ApiService.get("api/manageorganisations")
      .then(({ data }) => {
        console.log(data.data.response.organisations);
        context.commit(
          SET_ORGANISATIONS_INFO,
          data.data.response.organisations
        );
      })
      .catch(({ response }) => {
        context.commit(SET_ORGANISATIONS_ERROR, response.data.errors);
      });
  },
  [UPDATE_ORGANISATION_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_ORGANISATION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageorganisations/update", data).catch(
      ({ response }) => {
        context.commit(SET_ORGANISATIONS_ERROR, response.data.errors);
      }
    );
  },
  [CREATE_ORGANISATION_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_ORGANISATION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageorganisations/create", data).catch(
      ({ response }) => {
        context.commit(SET_ORGANISATIONS_ERROR, response.data.errors);
      }
    );
  },
  [DELETE_ORGANISATION_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_ORGANISATION_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/manageorganisations/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_ORGANISATIONS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_ORGANISATIONS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ORGANISATIONS_INFO](state, organisations) {
    state.organisations = organisations;
    console.log(state.organisations);
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
