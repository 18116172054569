import ApiService from "@/core/services/api.service";

// action types
export const GET_THREATS = "getThreats";
export const UPDATE_THREAT_DETAILS = "updateThreatDetails";
export const CREATE_THREAT_DETAILS = "createThreatDetails";
export const DELETE_THREAT_DETAILS = "deleteThreatDetails";
export const DUPLICATE_THREAT_DETAILS = "duplicateThreatDetails";

export const CREATE_PRESETS = "createPresets";

// mutation types
export const SET_THREATS_ERROR = "setTHREATsError";
export const SET_THREATS_INFO = "setTHREATsInfo";
export const SET_THREATS_PRESETS = "setTHREATsPresets";


const state = {
  errors: null,
  threats: [],
  presets: [],
};

const getters = {
  currentThreatsData(state) {
    return state.threats;
  },
  currentThreatsPresetData(state) {
    return state.presets;
  },
};

const actions = {
  [GET_THREATS](context) {
    console.log("GET_THREATS");
    ApiService.setHeader();
    ApiService.get("api/managethreats")
      .then(({ data }) => {
        console.log('presets',data.data.response.presets);
        context.commit(SET_THREATS_INFO, data.data.response.threats);
        context.commit(SET_THREATS_PRESETS, data.data.response.presets);
      })
      .catch(({ response }) => {
        context.commit(SET_THREATS_ERROR, response.data.errors);
      });
  },
  [UPDATE_THREAT_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_THREAT_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/managethreats/update", data).catch(({ response }) => {
      context.commit(SET_THREATS_ERROR, response.data.errors);
    });
  },
  [CREATE_PRESETS](context, payload) {
    const data = payload;

    console.log("CREATE_PRESETS");
    ApiService.setHeader();
    ApiService.post("api/managethreats/addpresets", data).catch(({ response }) => {
      context.commit(SET_THREATS_ERROR, response.data.errors);
    });
  },  
  [CREATE_THREAT_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_THREAT_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/managethreats/create", data).catch(({ response }) => {
      context.commit(SET_THREATS_ERROR, response.data.errors);
    });
  },
  [DELETE_THREAT_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_THREAT_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/managethreats/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_THREATS_ERROR, response.data.errors);
      }
    );
  },
  [DUPLICATE_THREAT_DETAILS](context, payload) {
    const data = payload;
    ApiService.setHeader();
    ApiService.get("api/managethreats/duplicate/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_THREATS_ERROR, response.data.errors);
      }
    );
  },
  
};

const mutations = {
  [SET_THREATS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_THREATS_INFO](state, threats) {
    state.threats = threats;
    console.log(state.threats);
    state.errors = {};
  },
  [SET_THREATS_PRESETS](state, presets) {
    state.presets = presets;
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
