import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/workplans",
          name: "workplans",
          component: () => import("@/view/pages/work/plans/Index.vue"),
        },
        {
          path: "/statementofapplicability",
          name: "statementofapplicability",
          component: () => import("@/view/pages/work/soa/Index.vue"),
        },        
        {
          path: "/actionplans",
          name: "actionplans",
          component: () => import("@/view/pages/work/actions/Index.vue"),
        },
        {
          path: "/exceptions",
          name: "exceptions",
          component: () => import("@/view/pages/work/exceptions/Index.vue"),
        },
        {
          path: "/exemptions",
          name: "exemptions",
          component: () => import("@/view/pages/work/exemptions/Index.vue"),
        },
        {
          path: "/threats",
          name: "threats",
          component: () => import("@/view/pages/work/threats/Index.vue"),
        },
        {
          path: "/threats/:id",
          name: "threatsedit",
          component: () => import("@/view/pages/work/threats/Index.vue"),
        },
        {
          path: "/threats/:framework",
          name: "threatsbyID",
          component: () => import("@/view/pages/work/threats/Index.vue"),
        },        
        {
          path: "/actionplans/:id/:frameworkid",
          name: "actionplansselected",
          component: () => import("@/view/pages/work/actions/Index.vue"),
        },
        {
          path: "/taskmanager",
          name: "taskmanager",
          component: () => import("@/view/pages/work/tasks/Index.vue"),
        },
        {
          path: "/taskmanager/:id/:frameworkid",
          name: "taskmanagerselected",
          component: () => import("@/view/pages/work/tasks/Index.vue"),
        },
        {
          path: "/workplanmanage/:id",
          name: "workplanmanage",
          component: () => import("@/view/pages/work/plans/Manage.vue"),
        },
        {
          path: "/management/user",
          name: "managementuser",
          component: () => import("@/view/pages/management/users/Index.vue"),
        },
        {
          path: "/management/userroles",
          name: "managementuserroles",
          component: () => import("@/view/pages/management/userroles/Index.vue"),
        },
        {
          path: "/management/userdepartments",
          name: "managementuserdepartments",
          component: () => import("@/view/pages/management/userdepartments/Index.vue"),
        },
        {
          path: "/management/statuses",
          name: "managementstatuses",
          component: () => import("@/view/pages/management/statuses/Index.vue"),
        },
        
        {
          path: "/management/system",
          name: "managementsystem",
          component: () => import("@/view/pages/management/systems/Index.vue"),
        },
        {
          path: "/management/system/:id",
          name: "managementsystem",
          component: () => import("@/view/pages/management/systems/Index.vue"),
        },
        {
          path: "/management/organisation",
          name: "managementorganisation",
          component: () =>
            import("@/view/pages/management/organisations/Index.vue"),
        },
        {
          path: "/management/riskparameters",
          name: "riskparameters",
          component: () =>
            import("@/view/pages/management/riskparameters/Index.vue"),
        },        
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "edit",
              name: "edit",
              component: () => import("@/view/pages/profile/Edit.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
