import ApiService from "@/core/services/api.service";

// action types
export const GET_FRAMEWORKACTIONS = "getFRAMEWORKACTIONs";
export const UPDATE_FRAMEWORKACTION_DETAILS = "updateFRAMEWORKACTIONDetails";
export const CREATE_FRAMEWORKACTION_DETAILS = "createFRAMEWORKACTIONDetails";
export const DELETE_FRAMEWORKACTION_DETAILS = "deleteFRAMEWORKACTIONDetails";

// mutation types
export const SET_FRAMEWORKACTIONS_ERROR = "setFRAMEWORKACTIONsError";
export const SET_FRAMEWORKACTIONS_INFO = "setFRAMEWORKACTIONsInfo";

const state = {
  errors: null,
  frameworkactions: {},
};

const getters = {
  currentFRAMEWORKACTIONsData(state) {
    console.log("curentframeworkactions");
    return state.frameworkactions;
  },
};

const actions = {
  [GET_FRAMEWORKACTIONS](context,payload) {
    const data = payload;
    console.log("GET_FRAMEWORKACTIONS",payload);
    ApiService.setHeader();
    ApiService.post("api/manageframeworkactions",data)
      .then(({ data }) => {
        console.log('data.data.response.frameworkactions');
        console.log(data.data.response);
        context.commit(
          SET_FRAMEWORKACTIONS_INFO,
          data.data.response.frameworkactions
        );
      })
      .catch(({ response }) => {
        context.commit(SET_FRAMEWORKACTIONS_ERROR, response.data.errors);
      });
  },
  [UPDATE_FRAMEWORKACTION_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_FRAMEWORKACTION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworkactions/update", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKACTIONS_ERROR, response.data.errors);
      }
    );
  },
  [CREATE_FRAMEWORKACTION_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_FRAMEWORKACTION_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageframeworkactions/create", data).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKACTIONS_ERROR, response.data.errors);
      }
    );
  },
  [DELETE_FRAMEWORKACTION_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_FRAMEWORKACTION_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/manageframeworkactions/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_FRAMEWORKACTIONS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_FRAMEWORKACTIONS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FRAMEWORKACTIONS_INFO](state, frameworkactions) {
    state.frameworkactions = frameworkactions;
    console.log(state.frameworkactions);
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
