import ApiService from "@/core/services/api.service";

// action types
export const GET_USERDEPARTMENTS = "getUserdepartments";
export const UPDATE_USERDEPARTMENT_DETAILS = "updateUserdepartmentDetails";
export const CREATE_USERDEPARTMENT_DETAILS = "createUserdepartmentDetails";
export const DELETE_USERDEPARTMENT_DETAILS = "deleteUserdepartmentDetails";

// mutation types
export const SET_USERDEPARTMENTS_ERROR = "setUserdepartmentsError";
export const SET_USERDEPARTMENTS_INFO = "setUserdepartmentsInfo";

const state = {
  errors: null,
  userdepartments: {},
};

const getters = {
  currentUserdepartments(state) {
    console.log('currentUserdepartments',state)
    return state.userdepartments;
  },
};

const actions = {
  [GET_USERDEPARTMENTS](context) {
    console.log("GET_USERDEPARTMENTS");
    ApiService.setHeader();
    ApiService.get("api/manageuserdepartments")
      .then(({ data }) => {
        console.log(data);
        context.commit(SET_USERDEPARTMENTS_INFO, data.data.response.userdepartments);
      })
      .catch(({ response }) => {
        context.commit(SET_USERDEPARTMENTS_ERROR, response.data.errors);
      });
  },
  [UPDATE_USERDEPARTMENT_DETAILS](context, payload) {
    const data = payload;

    console.log("UPDATE_USERDEPARTMENT_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageuserdepartments/update", data).catch(({ response }) => {
      context.commit(SET_USERDEPARTMENTS_ERROR, response.data.errors);
    });
  },
  [CREATE_USERDEPARTMENT_DETAILS](context, payload) {
    const data = payload;

    console.log("CREATE_USERDEPARTMENT_DETAILS");
    ApiService.setHeader();
    ApiService.post("api/manageuserdepartments/create", data).catch(({ response }) => {
      context.commit(SET_USERDEPARTMENTS_ERROR, response.data.errors);
    });
  },
  [DELETE_USERDEPARTMENT_DETAILS](context, payload) {
    const data = payload;

    console.log("DELETE_USERDEPARTMENT_DETAILS");
    ApiService.setHeader();
    ApiService.delete("api/manageuserdepartments/delete/" + data.id).catch(
      ({ response }) => {
        context.commit(SET_USERDEPARTMENTS_ERROR, response.data.errors);
      }
    );
  },
};

const mutations = {
  [SET_USERDEPARTMENTS_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USERDEPARTMENTS_INFO](state, userdepartments) {
    state.userdepartments = userdepartments;
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
